<template>
  <v-container data-test="patient">
    <v-row align="center" justify="center">
      <v-card elevation="0" width="100%" max-width="1100" class="transparent mb-5">
        <v-toolbar flat class="transparent mb-4">
          <h1 class="headline black--text mr-3">
            <router-link to="/pacientes">{{ $t('patient.patients') }}</router-link>
            / {{ paciente.fullName }}
          </h1>
          <v-spacer />
          <div v-if="!loading" sm2>
            <div v-if="paciente.sin_verificar_rrhh === 1" class="px-4 pt-4 app--page-header">
              <v-chip label outlined color="orange">{{ $t('common.sin_verificar') }} </v-chip>
            </div>
            <div v-else-if="paciente.centro !== ''" class="px-4 pt-4 app--page-header">
              <v-chip label outlined color="blue">{{ paciente.centro }}</v-chip>
            </div>
          </div>
        </v-toolbar>
        <v-card>
          <v-tabs v-model="tab" background-color="grey lighten-3">
            <v-tab
              v-for="(item, index) in tabs"
              :key="tabs_key[index]"
              data-navigation="patient-tab"
              @click="loadDataOfTab(index, item)"
            >
              {{ loading ? '' : item }}
            </v-tab>
          </v-tabs>

          <div v-if="loading" class="text-center pa-5">
            <v-progress-circular width="2" color="primary" indeterminate />
          </div>

          <v-tabs-items v-if="!loading" v-model="tab">
            <v-tab-item key="0">
              <v-card flat>
                <v-card-text>
                  <form class="form_datos_personales">
                    <v-layout wrap>
                      <v-flex xs12 sm6 lg6>
                        <v-text-field
                          data-test="patient-edit-input-name"
                          v-model="paciente.nombre"
                          filled
                          dense
                          outlined
                          type="text"
                          class="mx-1"
                          :label="$t('patient.first_name') + ' *'"
                          :rules="ruleRequired"
                          @change="modificaDato(true)"
                        />
                      </v-flex>
                      <v-flex xs12 sm6 lg6>
                        <v-text-field
                          data-test="patient-edit-input-surname"
                          v-model="paciente.apellidos"
                          filled
                          dense
                          outlined
                          type="text"
                          class="mx-1"
                          :label="$t('patient.last_name') + ' *'"
                          :rules="ruleRequired"
                          @change="modificaDato(true)"
                        />
                      </v-flex>
                      <v-flex xs12 sm8 lg8>
                        <v-identify-document
                          data-test="patient-input-document-type"
                          :dense="true"
                          :filled="true"
                          :id-document="paciente.dni"
                          :id-document-type="paciente.tipoDocumento"
                          class="mx-1"
                          use-required
                          @valid="handleIdErrors"
                          @input="setIdDocument"
                        />
                      </v-flex>
                      <v-flex xs12 sm4>
                        <v-menu
                          ref="fechaNacimientoMenu"
                          v-model="fechaNacimientoMenu"
                          :close-on-content-click="false"
                          :return-value.sync="paciente.fechaNacimiento"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="dateFormatted"
                              filled
                              dense
                              outlined
                              :label="$t('patient.birthdate') + ' *'"
                              append-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            data-test="patient-edit-input-birthdate"
                            ref="picker"
                            v-model="date"
                            no-title
                            scrollable
                            class="input_fnacimiento"
                            first-day-of-week="1"
                            :locale="this.$i18n.locale"
                            :min="datePickerMinDate"
                            :max="new Date().toISOString().substr(0, 10)"
                            @input="$refs.fechaNacimientoMenu.save(date)"
                          />
                        </v-menu>
                      </v-flex>
                      <v-flex v-if="!es_tutelado" sm6 lg6>
                        <v-text-field
                          data-test="patient-edit-input-email"
                          v-model="paciente.email"
                          filled
                          dense
                          outlined
                          type="mail"
                          class="mx-1"
                          :label="$t('message.email') + ' *'"
                          :rules="ruleEmail"
                          @change="modificaDato(true)"
                        />
                      </v-flex>
                      <v-flex v-if="!es_tutelado" sm6 lg6>
                        <v-text-field
                          data-test="patient-edit-input-email-repeat"
                          v-model="paciente.email_repeat"
                          filled
                          dense
                          outlined
                          onpaste="return false"
                          class="mx-1"
                          :label="$t('message.email_repeat') + ' *'"
                          type="mail"
                          required
                          @change="modificaDato(true)"
                        />
                      </v-flex>
                      <v-flex v-if="!es_tutelado" xs12 sm4 lg4 class="px-1">
                        <v-tel-field
                          data-test="patient-edit-input-phone"
                          v-model="patientPhone"
                          outlined
                          filled
                          dense
                          :label="$t('common.phone_number') + (isPhoneMandatoryForPatient ? ' *' : '')"
                          :allow-web-phone="true"
                          :rules="isPhoneMandatoryForPatient ? ruleRequired : []"
                          v-bind="bindProps"
                          @input="onInputPhone"
                          @call="callEvent"
                        />
                      </v-flex>
                      <v-flex xs12 sm4 lg4>
                        <VGenderSelect
                          :filled="false"
                          :dense="true"
                          :rounded="false"
                          :gender="paciente.sexo"
                          @input="inputGender"
                        />
                      </v-flex>
                      <v-flex xs12 sm4 lg4>
                        <v-select
                          data-test="patient-edit-input-lang"
                          v-model="paciente.idioma"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :items="idiomas"
                          :label="$t('common.lang')"
                          auto
                          required
                          item-text="name"
                          item-value="id"
                        />
                      </v-flex>

                      <template v-if="!es_tutelado">
                        <v-flex v-if="!es_tutelado" xs12 sm4 lg4>
                          <v-autocomplete
                            data-test="patient-edit-input-country"
                            v-model="paciente.pais_iso"
                            filled
                            dense
                            outlined
                            class="mx-1"
                            :items="paises"
                            item-text="name"
                            item-value="iso"
                            hide-no-data
                            :label="$t('patient.pais')"
                            @input="modificaDato(true)"
                          />
                        </v-flex>
                        <v-flex v-if="!es_tutelado" xs12 sm4 lg4>
                          <v-select
                            v-if="paciente.pais === 'España'"
                            v-model="paciente.provincia_id"
                            filled
                            dense
                            outlined
                            class="mx-1"
                            :items="provincias"
                            :label="$t('common.province')"
                            auto
                            item-text="nombre"
                            item-value="id"
                          />
                          <v-text-field
                            data-test="patient-edit-input-country"
                            v-else
                            v-model="paciente.provincia"
                            filled
                            dense
                            outlined
                            class="mx-1"
                            :label="$t('common.province')"
                            type="text"
                            @change="modificaDato(true)"
                          />
                        </v-flex>
                        <v-flex v-if="!es_tutelado" xs12 sm4 lg4>
                          <v-text-field
                            data-test="patient-edit-input-location"
                            v-model="paciente.localidad"
                            filled
                            dense
                            outlined
                            class="mx-1"
                            :label="$t('common.location')"
                            type="text"
                            @change="modificaDato(true)"
                          />
                        </v-flex>

                        <v-flex v-if="!es_tutelado" sm3 lg3>
                          <v-text-field
                            data-test="patient-edit-input-cp"
                            v-model="paciente.codigoPostal"
                            filled
                            dense
                            outlined
                            class="mx-1"
                            :label="$t('common.cp')"
                            type="text"
                            @change="modificaDato(true)"
                          />
                        </v-flex>
                        <v-flex v-if="!es_tutelado" sm9 lg9>
                          <v-text-field
                            data-test="patient-edit-input-address"
                            v-model="paciente.direccion"
                            filled
                            dense
                            outlined
                            class="mx-1"
                            :label="$t('common.address')"
                            type="text"
                            append-icon="mdi-map-marker"
                            @change="modificaDato(true)"
                          />
                        </v-flex>
                        <v-flex v-if="showPersonalDoctorAssistantAssignment" sm12 lg12 class="mb-3">
                          <PersonalDoctorsSelector
                            :patient-id="paciente.docline_api_id"
                            :company-id="authUser.company_docline_api_id"
                            data-test="patient_personal-doctors-assignment"
                            @personalDoctorSelected="personalDoctorSelected"
                            @deassignedPersonalDoctor="setDeassignPersonalDoctor"
                          />
                        </v-flex>
                        <v-flex v-if="showPersonalDoctorProfessionalAssignment" sm12 lg12 class="mb-3">
                          <PersonalDoctorsCheckbox
                            :professional-id="authUser.user.professional_docline_api_id"
                            :patient-id="paciente.docline_api_id"
                            :company-id="authUser.company_docline_api_id"
                            data-test="patient_personal-doctors-assignment"
                            @personalDoctorChanged="personalDoctorChanged"
                            @personalDoctorChecked="personalDoctorChecked"
                          />
                        </v-flex>
                      </template>

                      <v-flex xs12>
                        <v-textarea

                          v-model="paciente.notas"
                          filled
                          outlined
                          :label="$t('patient.notas')"
                          @change="modificaDato(true)"
                        />
                      </v-flex>
                      <v-flex xs12 sm3 lg3>
                        <v-text-field
                          v-if="historial.id > 0"
                          v-model="paciente.num_historia"
                          filled
                          outlined
                          class="mx-1"
                          :label="$t('patient.num_historia')"
                          type="text"
                          @change="modificaDato(true)"
                        />
                      </v-flex>
                      <v-flex v-if="showHealthCardNumber" xs12 sm12 lg12>
                        <v-text-field
                          v-model="paciente.numsegsocial"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :label="$t('common.numero_seg_social')"
                          type="text"
                          required
                        />
                      </v-flex>
                      <v-flex xs12 class="text-right">
                        <v-btn
                          v-if="!es_tutelado && !loading"
                          class="ma-2"
                          :disabled="disabledButton"
                          color="primary"
                          outlined
                          @click="cuidarAlguien(true)"
                        >
                          {{ $t('patient.cuidar_alguien') }}
                        </v-btn>
                        <v-btn data-test="patient-edit-button-save" depressed :disabled="disabledButton" color="primary" @click="editarPaciente()">
                          {{ $t('common.save') }}
                        </v-btn>
                      </v-flex>
                      <v-flex>
                        <cuidar
                          :paciente="paciente"
                          :es_tutelado="es_tutelado"
                          :mostrar_nuevo="dialog_nuevo_cuidar"
                          @dialogNuevoCuidar="cuidarAlguien"
                          @mostrarAlert="mostrarAlert"
                        />
                      </v-flex>
                    </v-layout>
                  </form>
                </v-card-text>
              </v-card>
              <HistoricPersonalDoctors
                v-if="showPersonalDoctorHistoric"
                :patient-id="paciente.docline_api_id"
                :company-id="authUser.company_docline_api_id"
                data-test="patient_historic-personal-doctors"
              />
            </v-tab-item>

            <v-tab-item v-if="permissions.clinicalHistory && !is_receptionist && !es_rrhh" key="historial">
              <history
                :historial="historial"
                :paciente="paciente"
                :show-dont-have-service="showDontHaveService"
                :anestesia="anestesia"
                :especialidades="especialidades"
                :antecedentes="antecedentes"
                :clinical-records="clinicalRecords"
                :check-status="checkStatus"
                :signature="signature"
                @mostrarAlert="mostrarAlert"
                @modificaDato="modificaDato"
                @reloadDiagnoses="reloadClinicalHistory"
              />
            </v-tab-item>
            <v-tab-item
              v-if="(availablePrescriptionTypes > 0) && !is_receptionist && !es_rrhh"
              key="receta"
            >
              <v-expansion-panels v-if="availablePrescriptionTypes > 1">
                <v-expansion-panel v-if="!showDontHaveService && pdfPrescriptionAvailable" popout>
                  <v-expansion-panel-header>
                    <h4>{{ $t('recipe.paper_recipe') }}</h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-layout wrap>
                      <v-flex sm12 lg12>
                        <recetas :idpaciente="$route.params.id" :recetas="recetas" />
                      </v-flex>
                    </v-layout>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="electronicPrescriptionAvailable" popout :readonly="twoFaDisabled" @click="show2fa">
                  <v-expansion-panel-header id="prescription-expand">
                    <h4>{{ $t('recipe.title') }}</h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-layout wrap>
                      <v-flex sm12 lg12>
                        <treatment-section />
                      </v-flex>
                    </v-layout>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="parapharmacyPrescriptionAvailable" popout>
                  <v-expansion-panel-header id="prescription-expand">
                    <h4>{{ $t('parapharmacy.prescription') }}</h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-layout wrap>
                      <v-flex sm12 lg12>
                        <ParapharmacyPrescriptions
                          v-if="paciente.id"
                          :patient-id="paciente.id"
                          :patient-uuid="paciente.docline_api_id"
                        />
                      </v-flex>
                    </v-layout>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <recetas v-else-if="pdfPrescriptionAvailable" :idpaciente="$route.params.id" :recetas="recetas" />
              <treatment-section v-else-if="electronicPrescriptionAvailable" />
              <ParapharmacyPrescriptions
                v-else-if="parapharmacyPrescriptionAvailable && paciente.id"
                :patient-id="paciente.id"
                :patient-uuid="paciente.docline_api_id"
              />
            </v-tab-item>

            <v-tab-item v-if="permissions.documents" key="documentos">
              <documentos
                v-if="!showDontHaveService"
                :documentos="documentos"
                :pacientes="pacientes"
                :patient="paciente"
                :is-required-data="isRequiredData"
                :on-required-data="onRequiredData"
                @reload="reloadDocuments"
              />
              <div v-else>
                <no-service-granted view="documentos" />
              </div>
            </v-tab-item>

            <v-tab-item v-if="showMessageModule && permissions.messaging && !es_rrhh" key="4">
              <mensajeria
                v-if="!showDontHaveService"
                :idpaciente="$route.params.id"
                :mensajerias="mensajerias"
                :pacientes="pacientes"
                :specialties="specialties"
                :thread-id-selected="threadIdSelected"
              />
              <div v-else>
                <no-service-granted view="mensajeria" />
              </div>
            </v-tab-item>

            <v-tab-item v-if="permissions.videoConsultation !== false" key="videoconsultas">
              <videoconsultas
                v-if="!showDontHaveService"
                :idpaciente="$route.params.id"
                :videoconsultas="videoconsultas"
                :pacientes="pacientes"
                :profesionales="profesionales"
                :especialidades="especialidades"
                :specialties="specialties"
                :tiene-video="tieneVideo"
                @loadvideos="loadVideos"
              />
              <div v-else>
                <no-service-granted view="videoconsulta" />
              </div>
            </v-tab-item>

            <v-tab-item v-if="es_rrhh" key="revisiones">
              <revisionmedica
                :pacientes="pacientes"
                :idpaciente="$route.params.id"
                :revisiones.sync="revisiones"
                @updateRevisions="updateRevisions"
              />
            </v-tab-item>

            <v-tab-item v-if="authUser.show_informed_consent" :key="'autorizaciones'">
              <authorizations :patient-id="$route.params.id" :authorizations="informedConsents" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>

        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          :color="alerta.color"
          top="top"
          :multi-line="mode === 'multi-line'"
          :vertical="mode === 'vertical'"
        >
          <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }} </v-icon>&nbsp;{{ alerta.texto }}
        </v-snackbar>
        <!-- Dialog Salir sin guardar -->
        <div class="text-center">
          <v-dialog v-model="dialogSalirSinGuardar" width="500">
            <v-card>
              <v-card-title class="headline lighten-2">{{ $t('common.titulo_salir_sin_guardar') }} </v-card-title>
              <v-divider />
              <v-card-text>{{ $t('common.salir_sin_guardar') }}</v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" dark text @click="dialogSalirSinGuardar = false"
                  >{{ $t('common.cancel') }}
                </v-btn>
                <v-btn color="primary" dark text @click="confirmarSalir">{{ $t('common.confirm') }} </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <EmitParapharmacyForm
          v-if="dialogEmitParapharmacyModal"
          v-model="dialogEmitParapharmacyModal"
          :template-id="parapharmacyTemplate"
          :patient-id="paciente.id"
        />
      </v-card>
    </v-row>
    <v-row v-if="tab === 0 && paciente.id" align="center" justify="center">
      <v-card flat elevation="0" width="100%" max-width="1100" class="transparent mb-5">
        <Triage :patient-id="paciente.id" :triage-code="paciente.triage_code" />
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import NoServiceGranted from '@/components/common/NoServiceGranted.vue';
import Cuidar from '@/components/ehealth/paciente/Cuidar.vue';
import Documentos from '@/components/ehealth/paciente/Documentos.vue';
import History from '@/components/ehealth/paciente/History.vue';
import Mensajeria from '@/components/ehealth/paciente/Mensajeria.vue';
import Recetas from '@/components/ehealth/paciente/Recetas.vue';
import Revisionmedica from '@/components/ehealth/paciente/Revisionmedica.vue';
import Videoconsultas from '@/components/ehealth/paciente/Videoconsultas.vue';
import HistoricPersonalDoctors from '@/components/PersonalDoctors/HistoricPersonalDoctors.vue';
import PersonalDoctorsSelector from '@/components/PersonalDoctors/PersonalDoctorsSelector.vue';
import { isValidDni } from 'nif-dni-nie-cif-validation';

import {
  haveEprescriptionActive,
  haveEprescription,
  setSelectorsLanguages,
  setSelectorsDocumentType,
  isPhoneMandatoryForPatient,
} from '@/utils';
import TreatmentSection from '../components/treatments/TreatmentSection.vue';
import Authorizations from '@/components/ehealth/paciente/Authorizations.vue';
import {
  getPacienteUrl,
  editPatientPersonalDataUrl,
  getHeader,
  getListPatientInformedConsentsUrl,
  defaultCountry,
  talonarioUrl,
} from '@/config/config';
import VGenderSelect from '../modules/VuetifyGender/components/VGenderSelect.vue';
import { datePickerMinDate } from '@/services/dateHelper';
import Triage from '@/components/triage/PatientCode';
import PersonalDoctorsCheckbox from '@/components/PersonalDoctors/PersonalDoctorsCheckbox';
import { useAssignmentPersonalDoctor } from '@/use/PersonalDoctors/useAssignmentPersonalDoctor';
import { authUserService } from '@/services/auth-user';
import { navigateToEprescription } from '@/services/navigation';
import ParapharmacyPrescriptions from '@/views/patients/ParapharmacyPrescriptions.vue';
import VIdentifyDocument from "@/modules/VuetifyIdentityDocument/components/VIdentifyDocument.vue";
import EmitParapharmacyForm from "@/components/Parapharmacy/EmitForm/EmitParapharmacyForm.vue";

export default {
  components: {
    EmitParapharmacyForm,
    VIdentifyDocument,
    ParapharmacyPrescriptions,
    PersonalDoctorsCheckbox,
    History,
    VGenderSelect,
    Mensajeria,
    Videoconsultas,
    Documentos,
    Revisionmedica,
    Recetas,
    Cuidar,
    TreatmentSection,
    NoServiceGranted,
    Authorizations,
    Triage,
    HistoricPersonalDoctors,
    PersonalDoctorsSelector,
  },

  setup() {
    const {
      currentPersonalDoctor,
      retrievePatientPersonalDoctor,
      assignPersonalDoctor,
      deassignPersonalDoctor,
    } = useAssignmentPersonalDoctor();

    return {
      currentPersonalDoctor,
      retrievePatientPersonalDoctor,
      assignPersonalDoctor,
      deassignPersonalDoctor,
    };
  },

  props: {
    prescriptionToExpand: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      informedConsents: [],
      dialogSalirSinGuardar: false,
      pageNext: {},
      esta_modificando: false,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      loading: true,
      disabledButton: true,
      snackbar: false,
      timeout: 5000,
      mode: '',
      tabs: ['personalData', 'History', 'Prescriptions', 'Documents', 'Messages', 'Videoconsultations'],
      tabs_key: [],
      tab: 0,
      anestesia: {},
      profesionales: [],
      especialidades: [],
      precargado: [true, false, false, false, false, false],
      idiomas: [
        { id: 'es', name: 'Español' },
        { id: 'en', name: 'Ingles' },
      ],
      tiposDocumento: [
        { id: 1, name: 'DNI' },
        { id: 2, name: 'NIE' },
        { id: 3, name: 'Pasaporte' },
        { id: 4, name: 'Otro' },
      ],
      provincias: [],
      paciente: {
        fullName: '',
        tipoDocumento: 0,
      },
      tipoDocumento: 0,
      historial: {},
      clinicalRecords: {},
      mensajerias: [],
      specialties: [],
      videoconsultas: {
        porconfirmar: {},
        pendientes: {},
        terminadas: {},
        tipos: [],
      },
      documentos: [],
      revisiones: [],
      recetas: [],
      alerta: {
        texto: '',
        icono: '',
        color: 'info',
      },
      antecedentes: [],
      rules: {},
      ruleRequired: [],
      ruleEmail: [],
      step_historia: 1,
      pacientes: [],
      date: null,
      dateFormatted: null,
      fechaNacimientoMenu: false,
      paises: [],
      checkStatus: {},
      mostrar: {
        mas_info_antecedentes_maternos: false,
        mas_info_antecedentes_paternos: false,
        mas_info_antecedentes_hermanos: false,
        mas_info_antecedentes_cirugias: false,
        mas_info_enfermedades_cronicas: false,
        mas_info_habitos_toxicos: false,
      },
      antecedentes_maternos: [],
      antecedentes_paternos: [],
      antecedentes_hermanos: [],
      antecedentesDoctorClic: {},
      cirugias: [],
      enfermedades_cronicas: [],
      toxicos: [],
      es_tutelado: false,
      dialog_nuevo_cuidar: false,
      tieneVideo: true,
      custom_template:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== '',
      es_rrhh:
        window.localStorage.getItem('auth_ehealth') &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 4,
      is_receptionist:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3,
      showHealthCardNumber: false,
      showMessageModule:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['servicios_activos'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['servicios_activos']['mensajeria'] === 1,
      phonePatient: {
        isValid: false,
      },
      patientPhone: '',
      isValidPhone: true,
      bindProps: {
        mode: 'international',
        defaultCountry: defaultCountry ? defaultCountry : 'ES',
        preferredCountries: ['ES'],
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephonePatient',
        maxLen: 17,
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      showElectronicPrescription: false,
      showDontHaveService: false,
      threadIdByQueryString: '',
      threadIdSelected: '',
      datePickerMinDate: datePickerMinDate(),
      isPhoneMandatoryForPatient: isPhoneMandatoryForPatient(),
      personalDoctor: null,
      personalDoctorToDeassign: null,
      signature: null,
      personalDoctorCheck: false,
      personalDoctorChange: false,
      dialogEmitParapharmacyModal: false,
      parapharmacyTemplate: null,
      dniError: false,
      isSavedDocumentIdValid: false,
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
      appDisplayName: state => state.app.displayName,
      companyPermissions: state => state.account.authUser.company.permissions,
    }),

    ...mapGetters({
      twoFaEnabled: 'account/twoFaEnabled',
      useNewAccountPage: 'app/getFeatureNewAccountPageFlag',
      repHomologation: 'app/getFeatureRepHomologationFlag',
    }),

    isRequiredData() {
      return !this.isSavedDocumentIdValid;
    },

    computedDateFormatted() {
      return this.formatDate(this.date);
    },

    showPersonalDoctorAssistantAssignment() {
      return (
        authUserService.isReceptionistRol() &&
        this.companyPermissions &&
        this.companyPermissions.personalDoctor &&
        this.companyPermissions.personalDoctor.personalDoctorAssistant
      );
    },

    showPersonalDoctorProfessionalAssignment() {
      return (
        authUserService.isProfessionalRol() &&
        this.companyPermissions &&
        this.companyPermissions.personalDoctor &&
        this.companyPermissions.personalDoctor.personalDoctorProfessional
      );
    },

    showPersonalDoctorHistoric() {
      return this.showPersonalDoctorAssistantAssignment || this.showPersonalDoctorProfessionalAssignment;
    },

    twoFaDisabled() {
      return !this.twoFaEnabled && this.useNewAccountPage && this.repHomologation;
    },

    electronicPrescriptionAvailable() {
      return (
        (this.permissions.haveElectronicPrescription && !haveEprescriptionActive()) ||
        (this.permissions.haveElectronicPrescription && haveEprescriptionActive())
      );
    },
    pdfPrescriptionAvailable() {
      return this.permissions.settingsBook && this.permissions.prescriptions;
    },

    parapharmacyPrescriptionAvailable() {
      return (
        typeof this.permissions.parapharmacyPrescription !== 'undefined' &&
        this.permissions.settingsSign &&
        ((!this.companyPermissions?.parapharmacyPrescription && this.authUser?.user?.professional_parapharmacy_prescription_service) ||
          (this.companyPermissions?.parapharmacyPrescription && this.authUser?.user?.professional_parapharmacy_prescription_service))
      );
    },

    availablePrescriptionTypes() {
      let count = 0;

      if (this.showElectronicPrescription) {
        count += 1;
      }

      if (this.permissions.prescriptions) {
        count += 1;
      }

      if (this.parapharmacyPrescriptionAvailable) {
        count += 1;
      }

      return count;
    },
    showVideoconsultations() {
      if (this.companyPermissions) {
        return this.permissions.videoConsultation && this.companyPermissions.videoConsultation;
      }
      return this.permissions.videoConsultation;
    },
    showMessaging() {
      if (this.companyPermissions) {
        return this.permissions.messaging && this.companyPermissions?.messaging && this.showMessageModule;
      }
      return this.permissions.messaging && this.showMessageModule;
    }
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    fechaNacimientoMenu(val) {
      val &&
        this.$nextTick(() => {
          this.$refs.picker.activePicker = 'YEAR';
          return;
        });
    },
    tipoDocumento() {
      this.verificarDocumentoIdentidad();
    },
  },

  mounted() {
    this.threadIdByQueryString = this.$route.params.objectId ?? '';
    this.personalizacionesEntorno();
    this.loadSignature();
    this.getPatient();

    if (typeof this.permissions.profilesFormWithInsuredNumber !== 'undefined') {
      this.showHealthCardNumber = this.permissions.profilesFormWithInsuredNumber;
    }

    if (typeof this.$route.query.receta !== 'undefined' && this.$route.query.receta !== null) {
      this.showElectronicPrescriptionTab();
    }

    this.setRules();
  },

  created() {
    if (
      typeof this.permissions.haveElectronicPrescription !== 'undefined' &&
      haveEprescription() &&
      !this.twoFaDisabled
    ) {
      this.showElectronicPrescription = this.permissions.haveElectronicPrescription;
    }

    if (haveEprescriptionActive()) {
      this.showDontHaveService = true;
    }

    const mensaje = JSON.parse(window.localStorage.getItem('mensaje_ehealth'));
    if (mensaje && mensaje.texto !== '') {
      this.alerta.texto = mensaje.texto;
      this.alerta.color = mensaje.color;
      this.alerta.icono = mensaje.icono;
      localStorage.removeItem('mensaje_ehealth');
      this.snackbar = true;
      this.timeout = 12000;
    }
  },
  methods: {
    ...mapMutations({
      setTwoFaDialogVisibility: 'layout/setTwoFaDialogVisibility',
    }),

    callEvent(number) {
      this.$dialog.call({
        number: number,
        name: this.paciente.fullName,
      });
    },

    onRequiredData() {
      this.tab = 0;
    },

    setRules() {
      const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      this.rules = {
        required: value => !!value || this.$t('common.obligatorio'),
        email: v => regex.test(v) || this.$t('errors.email_invalid'),
      };
      this.ruleEmail = [this.rules.required, this.rules.email];
      this.ruleRequired = [this.rules.required];
    },

    onInputPhone(formattedNumber, phone) {
      let prefixCountry = '+' + phone.country.dialCode;
      if (phone.valid && phone.country.iso2 !== phone.regionCode) {
        prefixCountry = phone.number.international.split(' ')[0];
      }
      this.paciente.telefono = phone.number.significant;
      this.paciente.codigoTelefono = prefixCountry;
      this.paciente.phonePrefix = prefixCountry;
      this.isValidPhone = phone.valid;
    },

    loadSignature() {
      const formData = new FormData();
      formData.append('user_id', this.authUser.id);
      formData.append('accion', 'indexFirma');
      this.$http.post(talonarioUrl, formData, { headers: getHeader() }).then(response => {
        this.signature = response.data.firma !== '' ? response.data.firma : null;
      });
    },

    reloadClinicalHistory() {
      const param = {
        id: this.authUser.id,
        patient_id: this.$route.params.id,
        idioma: this.$i18n.locale,
      };

      this.$http
        .post(getPacienteUrl, param, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data && response.data.d_personales) {
            this.historial = response.data.historial;
          }
        })
        .catch(err => {
          this.$log.error(err);
          if (err.status === 403) {
            location.reload();
          }
        });
    },

    openPrescriptionTab() {
      this.tab = 2;
    },

    openPrescription(id) {
      this.openPrescriptionTab();
      return navigateToEprescription(id);
    },

    getPatient() {
      const param = {
        id: this.authUser.id,
        patient_id: this.$route.params.id,
        idioma: this.$i18n.locale,
      };
      this.$http
        .post(getPacienteUrl, param, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data && response.data.d_personales) {
            this.especialidades = [];
            this.profesionales = response.data.profesionales;
            this.specialties = response.data.specialties;
            this.paciente = response.data.d_personales,

            this.setPatientPhone();

            const splits = this.paciente.telefono !== null ? this.paciente.telefono.split(' ') : [];
            if (splits.length > 1) {
              this.paciente.telefono = splits[1];
            }

            this.phonePatient.isValid = true;
            this.paciente.phoneNumber = this.paciente.telefono;
            this.paciente.phonePrefix = this.paciente.codigoTelefono;
            if (this.paciente.tutor_id > 0) {
              this.es_tutelado = true;
            }
            if (this.paciente.anestesista === 1) {
              this.anestesia = response.data.anestesia;
            }
            this.pacientes = response.data.pacientes;
            this.provincias = response.data.provincias;
            this.date = this.paciente.fechaNacimiento;
            this.paciente.email_repeat = this.paciente.email;
            this.paciente.numsegsocial =
              typeof this.paciente.num_seg_social !== 'undefined' ? this.paciente.num_seg_social : '';
            this.tipoDocumento = response.data.d_personales.tipoDocumento;
            this.isSavedDocumentIdValid = isValidDni(response.data.d_personales.dni);
            this.paciente.dni_previo = this.paciente.dni;
            this.paises = response.data.paises;
            if (this.is_receptionist) {
              this.tabs = [this.$t('patient.personal_data'), this.$t('common.videoconferences')];
              this.tabs_key = ['personal', 'videoconsultas'];
              this.precargado = [true, false];
              if (this.permissions.documents) {
                this.tabs.splice(1, 0, this.$t('common.documents'));
                this.tabs_key.splice(1, 0, 'documentos');
                this.precargado.push(false);
              }
            } else if (this.authUser !== null && this.authUser.nombreEmpresa === 'Docline Hospital') {
              this.tabs = [
                this.$t('patient.personal_data'),
                this.$t('common.messaging'),
                this.$t('common.videoconferences'),
              ];
              this.tabs_key = ['personal', 'mensajes', 'videoconsultas'];
              this.precargado = [true, true, false];
              this.mensajerias = response.data.mensajeria;
            } else {
              this.historial = response.data.historial;
              this.paciente.num_historia = this.historial.num_historia;
              this.paciente.notas = this.historial.notas;
              if (response.data.historial) {
                this.antecedentes_maternos = response.data.historial.antecedentes_maternos;
                this.antecedentes_paternos = response.data.historial.antecedentes_paternos;
                this.antecedentes_hermanos = response.data.historial.antecedentes_hermanos;
                this.cirugias = response.data.historial.cirugias;
                this.enfermedades_cronicas = response.data.historial.enfermedades_cronicas;
                this.mensajerias = response.data.mensajeria;
                this.antecedentes.familiares = response.data.historial.antecedentes.familiares;
                this.antecedentes.cirugias = response.data.historial.antecedentes.cirugias;
                this.toxicos = response.data.historial.antecedentes.toxicos;
                this.checkStatus = response.data.check;
                this.clinicalRecords = {
                  maternal: this.antecedentes_maternos,
                  paternal: this.antecedentes_paternos,
                  brothers: this.antecedentes_hermanos,
                  surgeries: this.cirugias,
                  toxics: this.toxicos,
                  chronic_diseases: this.enfermedades_cronicas,
                  check: response.data.check,
                };
              }
              this.mensajerias = response.data.mensajeria;
              if (this.es_rrhh) {
                this.tabs = [
                  this.$t('patient.personal_data'),
                  this.$t('common.documents'),
                  this.$t('menu.citas'),
                  this.$tc('patient.revision_medica', 1),
                ];
                this.tabs_key = ['personal', 'documentos', 'videoconsultas', 'revisiones'];
                this.precargado = [true, false, false, false];
              } else {
                this.setTabs();
                if (this.prescriptionToExpand) {
                  this.openPrescription(this.prescriptionToExpand);
                }
              }
            }
            this.paciente.triage_code = response.data.triage_code;

            //get Informed Consents
            if (this.authUser.show_informed_consent) {
              this.getInformedConsents();
            }
            this.loading = false;
            this.disabledButton = false;
          } else {
            this.alerta.texto = this.$t('errors.not_access_patient');
            this.alerta.color = 'error';
            window.localStorage.setItem('mensaje_ehealth', JSON.stringify(this.alerta));
            this.$router.push({ path: '/pacientes' });
          }
        })
        .catch(err => {
          this.$log.error(err);
          if (err.status === 403) {
            location.reload();
          }
        })
        .finally(() => {
          if (typeof this.$route.params.tab !== 'undefined') {
            this.tabs.forEach(function(element, index) {
              const name = element
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase();
              if (this.$route.params.tab === name) {
                this.tab = index;
                this.loadDataOfTab(index);
              }
            }, this);
          }
        });
    },

    getInformedConsents() {
      this.$http
        .get(getListPatientInformedConsentsUrl + this.$route.params.id, {
          headers: getHeader(),
        })
        .then(response => {
          this.informedConsents = response.data;
        })
        .catch(() => {
          //
        });
    },

    setPatientPhone() {
      let phone = '';
      if (!this.isPhoneMandatoryForPatient && this.paciente.telefono === null) {
        this.patientPhone = this.paciente.phonePrefix = '';
        return;
      }

      if (this.paciente.telefono === null) {
        phone = '+34';
      } else {
        if (this.paciente.telefono.indexOf('+') === -1) {
          phone = this.paciente.codigoTelefono;
        }
        phone += this.paciente.telefono;
      }
      this.patientPhone = phone;
    },

    setTabs() {
      const all_tabs = [
        this.$t('patient.personal_data'),
        this.$t('patient.history_clinical'),
        this.$tc('common.prescription', 1),
        this.$t('common.documents'),
        this.$t('common.messaging'),
        this.$t('common.videoconferences'),
        this.$tc('common.authorization', 2),
      ];
      this.tabs = [];

      all_tabs.map(t => {
        switch (t) {
          case this.$t('common.messaging'):
            if (this.showMessaging) {
              this.tabs.push(t);
            }
            break;
          case this.$t('patient.history_clinical'):
            if (this.permissions.clinicalHistory) {
              this.tabs.push(t);
              this.precargado[1] = true;
            }
            break;
          case this.$tc('common.prescription', 1):
            if (this.permissions.prescriptions || this.showElectronicPrescription) {
              this.tabs.push(t);
            }
            break;
          case this.$t('common.documents'):
            if (this.permissions.documents) {
              this.tabs.push(t);
            }
            break;
          case this.$tc('common.videoconferences', 1):
            if (this.showVideoconsultations) {
              this.tabs.push(t);
            }
            break;
          case this.$tc('common.authorization', 2):
            if (this.authUser.show_informed_consent) {
              this.tabs.push(t);
            }
            break;
          default:
            this.tabs.push(t);
            break;
        }
      });

      const all_tabs_key = [
        'personal',
        'historial',
        'recetas',
        'documentos',
        'mensajes',
        'videoconsultas',
        'authorizations',
      ];

      this.tabs_key = [];

      all_tabs_key.map(tab_key => {
        switch (tab_key) {
          case 'mensajes':
            if (this.showMessaging) {
              this.tabs_key.push(tab_key);
            }
            break;
          case 'historial':
            if (this.permissions.clinicalHistory) {
              this.tabs_key.push(tab_key);
              this.precargado[1] = true;
            }
            break;
          case 'recetas':
            if (this.permissions.prescriptions || this.showElectronicPrescription) {
              this.tabs_key.push(tab_key);
            }
            break;
          case 'documentos':
            if (this.permissions.documents) {
              this.tabs_key.push(tab_key);
            }
            break;
          case 'videoconsultas':
            if (this.showVideoconsultations) {
              this.tabs_key.push(tab_key);
            }
            break;
          case 'authorizations':
            if (this.authUser.show_informed_consent) {
              this.tabs_key.push(tab_key);
            }
            break;
          default:
            this.tabs_key.push(tab_key);
            break;
        }
      });
      this.tab = 0;
    },

    personalizacionesEntorno() {
      if (this.authUser !== null && this.authUser.nombreEmpresa === 'Docline Hospital') {
        this.idiomas.push({ id: 'ca', name: this.$t('common.catalan') });
      } else {
        this.idiomas[0]['name'] = this.$t('common.spanish');
        this.idiomas[1]['name'] = this.$t('common.english');
        this.tiposDocumento[2]['name'] = this.$t('patient.pasaporte');
        this.tiposDocumento[3]['name'] = this.$t('common.otro');
      }
      this.tiposDocumento = setSelectorsDocumentType(this.tiposDocumento);
      this.idiomas = setSelectorsLanguages(this.idiomas);
      this.setUserLanguage();
    },

    loadDataOfTab(index) {
      if (!this.precargado[index] || this.tabs_key[index] === 'documentos') {
        this.requestHandler(index);
      }
    },

    show2fa() {
      if (this.twoFaDisabled) {
        this.setTwoFaDialogVisibility(true);
      }
    },

    requestHandler(index) {
      this.$http
        .post(
          getPacienteUrl,
          {
            id: this.authUser.id,
            patient_id: this.$route.params.id,
            tab: index,
            tabkey: this.tabs_key[index],
            idioma: this.$i18n.locale,
          },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data) {
            if (this.tabs_key[index] === 'documentos') {
              this.documentos = response.data.documentos;
            } else if (this.tabs_key[index] === 'videoconsultas') {
              this.videoconsultas = response.data.videoconsultas;
            } else if (this.tabs_key[index] === 'recetas') {
              this.recetas = response.data.recetas;
            }
            if (this.tabs_key[index] === 'revisiones') {
              this.revisiones = response.data.revisiones;
            }

            if (this.tabs_key[index] === 'mensajes') {
              this.mensajerias = response.data.mensajeria;
              if (this.threadIdByQueryString !== '') {
                this.threadIdSelected = this.threadIdByQueryString;
                this.threadIdByQueryString = '';
              }
            }
            this.precargado[index] = true;
          }
        });
    },

    reloadDocuments() {
      this.requestHandler(3);
    },

    inputGender(value) {
      this.paciente.sexo = value;
    },

    verificarDocumentoIdentidad() {
      this.paciente.tipoDocumento = this.tipoDocumento;
      if ((this.paciente.tipoDocumento === 1 || this.paciente.tipoDocumento === 2) && this.paciente.dni.length > 1) {
        let dni = this.paciente.dni;
        dni = dni.replace('-', '');
        const expresion_regular =
          this.paciente.tipoDocumento === 1
            ? /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i
            : /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
        if (expresion_regular.test(dni) !== true) {
          this.paciente.dni_previo = this.paciente.dni;
          this.paciente.dni = '';
          this.alerta.texto = this.$t('patient.dni_invalid');
        } else if (this.paciente.tipoDocumento === 1) {
          let numero = dni.substr(0, dni.length - 1);
          const letr = dni.substr(dni.length - 1, 1);
          numero = numero % 23;
          let letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
          letra = letra.substring(numero, numero + 1);
          if (letra !== letr.toUpperCase()) {
            this.paciente.dni_previo = this.paciente.dni;
            this.paciente.dni = '';
            this.alerta.texto = this.$t('patient.dni_invalid_letra');
          }
        }
        if (this.paciente.dni === '') {
          this.alerta.icono = '';
          this.alerta.color = 'error';
          this.snackbar = true;
        }
      }
    },

    errorAlert(mensaje) {
      this.alerta.texto = mensaje;
      this.alerta.icono = '';
      this.alerta.color = 'error';
      this.snackbar = true;
    },

    successAlert(mensaje) {
      this.alerta.texto = mensaje;
      this.alerta.icono = 'mdi-check';
      this.alerta.color = 'success';
      this.snackbar = true;
      this.esta_modificando = false;
    },

    validarFormulario() {
      this.timeout = 5000;
      this.alerta.texto = '';
      this.snackbar = false;
      const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (this.dniError) {
        this.alerta.texto = this.$t('patient.dni_invalid');
      }

      if (this.paciente.sexo === '') {
        this.alerta.texto = this.$t('errors.gender_required');
      }
      if (this.paciente.nombre.length === 0 || this.paciente.apellidos.length === 0) {
        this.alerta.texto = this.$t('patient.full_name_required');
      }
      if (this.paciente.nombre.length > 50) {
        this.alerta.texto = this.$t('patient.too_long_name');
      }
      if (this.paciente.apellidos.length > 50) {
        this.alerta.texto = this.$t('patient.too_long_surname');
      }
      // else if (this.paciente.dni.length < 1) {
      //   this.alerta.texto = this.$t('patient.dni_required');
      // }
      // else if (this.paciente.tipoDocumento === '0') {
      //   this.alerta.texto = this.$t('patient.type_dni_required');
      // }
      else if (this.paciente.fechaNacimiento) {
        // verificar que la fecha de nacimiento es menor a la actual
        const x = new Date();
        const fecha = this.paciente.fechaNacimiento.split('-');
        x.setFullYear(fecha[0], fecha[1] - 1, fecha[2]);
        const today = new Date();
        if (x >= today) {
          this.alerta.texto = this.$t('patient.birthdate_invalid');
        }
      }

      // validar email
      if (this.paciente.email === '') {
        this.alerta.texto = this.$t('errors.email_required');
      } else if (!regex.test(this.paciente.email)) {
        this.alerta.texto = this.$t('errors.email_invalid');
      } else if (this.paciente.email !== this.paciente.email_repeat) {
        this.alerta.texto = this.$t('errors.email_repeat');
      } else if (this.isPhoneMandatoryForPatient && typeof this.paciente.telefono === 'undefined') {
        this.alerta.texto = this.$t('registro.alert_phone_required');
        this.paciente.telefono = '';
      } else if (this.isPhoneMandatoryForPatient && (!this.phonePatient.isValid || !this.isValidPhone)) {
        this.alerta.texto = this.$t('errors.format_phone');
      } else if (!this.isPhoneMandatoryForPatient && typeof this.paciente.telefono === 'undefined') {
        this.paciente.telefono = '';
        this.paciente.phonePrefix = '';
      }

      return this.alerta.texto === '';
    },

    handleChangePatientDNI() {
      this.modificaDato(true);
      this.verificarDocumentoIdentidad();
    },

    async isMyPersonalDoctor(professionalId) {
      await this.retrievePatientPersonalDoctor(this.paciente.docline_api_id, this.authUser.company_docline_api_id);
      return this.currentPersonalDoctor === professionalId;
    },

    async editarPaciente() {
      this.paciente.fechaNacimiento = this.date;
      this.alerta.color = 'error';
      this.alerta.icono = '';
      const es_valido = this.validarFormulario();
      if (es_valido) {
        this.disabledButton = true;

        try {
          const response = await this.$http.post(
            editPatientPersonalDataUrl,
            { id: this.authUser.id, patient: this.paciente, language: this.$i18n.locale },
            { headers: getHeader() }
          );

          if (response.status === 200 && response.data) {
            if (response.data === '1') {
              if (this.showPersonalDoctorProfessionalAssignment && this.personalDoctorCheck) {
                const isMyPersonalDoctor = await this.isMyPersonalDoctor(
                  this.authUser.user.professional_docline_api_id
                );
                if (!isMyPersonalDoctor && this.personalDoctorChange) {
                  await this.assignPersonalDoctor(
                    this.paciente.docline_api_id,
                    this.authUser.user.professional_docline_api_id
                  );
                }

                if (isMyPersonalDoctor && !this.personalDoctorChange) {
                  await this.deassignPersonalDoctor(
                    this.paciente.docline_api_id,
                    this.authUser.user.professional_docline_api_id
                  );
                }
              } else if (this.showPersonalDoctorAssistantAssignment) {
                if (this.personalDoctor) {
                  await this.assignPersonalDoctor(this.paciente.docline_api_id, this.personalDoctor);
                  this.personalDoctor = null;
                }

                if (this.personalDoctorToDeassign) {
                  await this.deassignPersonalDoctor(this.paciente.docline_api_id, this.personalDoctorToDeassign);
                  this.personalDoctorToDeassign = null;
                }
              }

              this.isSavedDocumentIdValid = true;
              this.alerta.texto = this.$t('success.edit_patient');
              this.alerta.color = 'success';
              this.alerta.icono = 'mdi-check';
            } else if (response.data === '2') {
              this.alerta.texto = this.$t('errors.form_invalid');
            } else if (response.data === '3') {
              this.alerta.texto = this.$t('errors.existing_patient', {
                name: this.appDisplayName,
              });
            } else {
              this.alerta.texto = this.$t('errors.edit_patient');
            }
          } else {
            this.alerta.texto = this.$t('errors.edit_patient');
          }
        } catch (e) {
          this.alerta.color = 'error';
          if (e.data && e.data.error) {
            this.alerta.texto = this.$t(e.data.error);
          } else {
            this.alerta.texto = this.$t('errors.try_again');
          }
        } finally {
          this.loading = false;
          this.snackbar = true;
          this.esta_modificando = false;
          this.disabledButton = false;
        }
      } else {
        this.snackbar = true;
      }
    },

    handleIdErrors(isValid) {
      this.$nextTick(() => {
        this.dniError = !isValid;
      });

    },

    setIdDocument(data) {
      this.paciente.dni = data.idDocument;
      this.paciente.tipoDocumento = data.idDocumentType;
    },

    cuidarAlguien(estado) {
      this.dialog_nuevo_cuidar = estado;
    },

    mostrarAlert(alerta) {
      this.alerta = alerta;
      this.snackbar = true;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    modificaDato(val) {
      this.esta_modificando = val;
    },

    confirmarSalir() {
      this.dialogSalirSinGuardar = false;
      this.pageNext();
    },

    loadVideos() {
      const index = this.tabs_key.indexOf('videoconsultas');
      this.precargado[index] = false;
      this.loadDataOfTab(index);
    },

    deleteSpaces(str) {
      return str.replace(/ +/g, '');
    },

    showElectronicPrescriptionTab() {
      setTimeout(() => {
        const posEprescription = 2;
        document.querySelectorAll('.v-tab')[posEprescription].dispatchEvent(new Event('click'));
        setTimeout(() => {
          document.querySelector('#prescription-expand').click();
        }, 500);
      }, 500);
    },

    updateRevisions(data) {
      this.revisiones = data;
    },
    setUserLanguage() {
      if (this.idiomas.length === 1) {
        this.paciente.idioma = this.idiomas[0]['id'];
      }
    },

    personalDoctorSelected(personalDoctor) {
      this.personalDoctor = personalDoctor;
    },

    setDeassignPersonalDoctor(personalDoctor) {
      this.personalDoctorToDeassign = personalDoctor;
    },

    personalDoctorChecked() {
      this.personalDoctorCheck = true;
    },

    personalDoctorChanged(val) {
      this.personalDoctorChange = val;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.esta_modificando && to['name'] !== 'Login') {
      this.dialogSalirSinGuardar = true;
      this.pageNext = next;
      next(false);
    } else {
      next();
    }
  },
};
</script>
<style scoped lang="css">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
 {
  transform: translateX(10px);
  opacity: 0;
}

.loading_paciente {
  margin-top: 30px;
  text-align: center;
}

.form_datos_personales,
.form_historial_clinico {
  padding: 10px;
}

.label_genero {
  margin-bottom: 0px;
}

.radio_genero {
  margin-top: 3px;
}

.cabecera_tab {
  margin-top: -50px !important;
}

.input_fnacimiento {
  color: #000 !important;
}

.textarea_historia {
  width: 98%;
  margin: 0 auto;
}

.breadcrumb_item {
  text-transform: none;
  margin: 0;
  color: #0d4080;
}

.breadcrumb_item_divide {
  margin: 0 10px;
}

.vue-tel-input {
  border: none;
  border-radius: unset;
  border-bottom: 1px solid black;
}

@media only screen and (min-width: 660px) {
  .vue-tel-input {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 660px) {
  .vue-tel-input {
    margin-bottom: 1rem;
  }
}
</style>
