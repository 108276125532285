<template>
  <div id="revisionmedica">
    <div class="cabecera_nuevo_reconocimiento">
      <v-btn color="blue" dark @click="showModalNewRevision()"
        ><v-icon>mdi-plus</v-icon> {{ $t('patient.new_revision') }}</v-btn
      >
      <dialogForm :dialog="dialog" @showSnackbar="showSnackbar" @confirmNewRevision="confirmNewRevision" />
    </div>
    <div v-if="loading" class="loading_revision">
      <v-progress-linear indeterminate value="15" color="primary" />
    </div>
    <v-layout>
      <v-flex xs12>
        <v-list v-for="item in revisiones" :key="item.id + '_' + item.title" two-line>
          <v-list-item class="item_adjunto">
            <v-list-item>
              <v-chip :class="[estados[obtenerEstadoUnificado(item.estado)].chipClass]" text-color="white">
                <v-icon>{{ estados[obtenerEstadoUnificado(item.estado)].icono }}</v-icon
                >&nbsp;&nbsp;
                <span class="hidden-sm-and-down">{{ estados[obtenerEstadoUnificado(item.estado)].texto }}</span>
              </v-chip>
            </v-list-item>
            <v-list-item-content>
              <span class="d-inline-block">
                {{ $t('common.date') }} {{ $tc('patient.revision_medica', 1) }}:
                <span v-if="!item.hidedate">{{ item.fecha }}</span>
                <span v-else>
                  <v-menu
                    ref="menu"
                    v-model="item.menud"
                    class="d-inline-block"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="item.fecha"
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="iniFormatted"
                        class="inputFecha"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        @blur="fini = parseDate(iniFormatted)"
                      />
                    </template>

                    <v-date-picker
                      v-model="fini"
                      locale="es-es"
                      first-day-of-week="1"
                      no-title
                      scrollable
                      @change="cambiaFecha(item)"
                      @input="actualizaFechaAux"
                    />
                  </v-menu>
                </span>
              </span>
              <span v-if="item.videoconsulta_id === 0 || item.videoconsulta_id === ''">
                {{ $t('patient.fecha_ultimo_aviso') }}: {{ formatDateTimeToDate(item.fechaUltimoAviso) }}
              </span>
            </v-list-item-content>
            <v-list-item-content v-if="(item.file === null || item.file === '') && !loading">
              <div class="align-to-right">
                <v-btn
                  class="ma-2 hidden-xs-only"
                  tile
                  color="success"
                  @click="uploadCertificate(item.id, 'filecertif-' + item.id)"
                >
                  <v-icon left>mdi-cloud-upload</v-icon> {{ $t('documentos.certificado') }}
                </v-btn>
                <input
                  :id="'filecertif-' + item.id"
                  :ref="'filecertif-' + item.id"
                  type="file"
                  :data-id="item.id"
                  accept="application/vnd.ms-excel, application/vnd.ms-powerpoint,
                        text/plain, application/pdf, image/*, .pdf, video/mp4,video/x-m4v,video/avi, .pptx, .ppt"
                  style="display: none;"
                  @change="onFileCertif(item.id, 1)"
                />
              </div>
            </v-list-item-content>
            <v-list-item-content v-if="(item.file === null || item.file === '') && !loading">
              <v-btn
                class="ma-2 hidden-xs-only"
                tile
                color="error"
                @click="uploadCertificate(item.id, 'fileresig-' + item.id)"
              >
                <v-icon left>mdi-cloud-upload</v-icon> {{ $t('documentos.renuncia') }}
              </v-btn>
              <input
                :id="'fileresig-' + item.id"
                :ref="'fileresig-' + item.id"
                type="file"
                :data-id="item.id"
                accept="application/vnd.ms-excel, application/vnd.ms-powerpoint,
                        text/plain, application/pdf, image/*, .pdf, video/mp4,video/x-m4v,video/avi, .pptx, .ppt"
                style="display: none;"
                @change="onFileCertif(item.id, 2)"
              />
            </v-list-item-content>
            <v-list-item-action class="hidden-sm-and-down">
              <v-btn
                v-if="item.file !== null && item.file !== ''"
                style="margin-right: 1em;"
                depressed
                outlined
                icon
                fab
                dark
                color="primary"
                small
                @click="abrirRevision(idpaciente, item.file, item.id)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action class="hidden-sm-and-down">
              <v-btn
                v-if="es_doctorclic"
                slot="activator"
                :title="$t('patient.edit_date_revision')"
                icon
                ripple
                @click="editarFechaRevision(item)"
              >
                <v-icon :color="!item.edit ? 'grey' : 'green lighten-1'">{{ !item.edit ? 'edit' : 'save' }}</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider inset />
        </v-list>
      </v-flex>
    </v-layout>

    <v-alert v-if="revisiones.length === 0" color="light-blue" icon="mdi-link-off" :value="true">
      {{ $t('patient.sin_common', { name: $tc('patient.revision_medica', 2) }) }}
    </v-alert>

    <!-- Snackbar para mostrar avisos -->
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </div>
</template>

<script>
import DialogForm from '@/components/common/DialogForm.vue';
import {
  getRevisionFileUrl,
  modificarFechaRevMed,
  getHeader,
  nameEnv,
  saveStatusReco,
  saveNewReco,
} from '../../../config/config';

export default {
  name: 'Revisionmedica',
  components: {
    DialogForm,
  },
  props: {
    revisiones: {
      type: Array,
      default: () => [],
    },
    pacientes: {
      type: Array,
      default: () => [],
    },
    idpaciente: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      snackbar: false,
      timeout: 5000,
      mode: '',
      alerta: {
        texto: '',
        icono: 'mdi-message-text',
        color: 'info',
      },
      dialog_nuevomensaje: false,
      disabledButton: false,
      selected: {
        estado: '',
        video: {},
        indice: 0,
      },
      estados: [
        { texto: 'Pendiente', chipClass: 'orange white--text', icono: 'mdi-check' },
        { texto: 'Aceptado', chipClass: 'green white--text', icono: 'mdi-check' },
        { texto: 'Renuncia', chipClass: 'blue white--text', icono: 'mdi-check' },
        { texto: 'No responde', chipClass: 'orange white--text', icono: 'mdi-check' },
        { texto: 'Pte RRHH', chipClass: 'orange white--text', icono: 'mdi-check' },
        { texto: 'No Apto', chipClass: 'red white--text', icono: 'mdi-check' },
        { texto: 'Pte Renuncia', chipClass: 'red white--text', icono: 'mdi-check' },
        { texto: 'Renuncia Denegada', chipClass: 'red white--text', icono: 'mdi-check' },
      ],
      nuevorevision: {
        seleccionado: false,
        file: '',
      },
      dialog: {
        show: false,
        title: '',
        text: '',
        type: '',
        form: [],
        button: {
          color: '',
          action: '',
          text: '',
          icon: '',
        },
      },

      dialog_confirm: {
        dialog: false,
        tipo: '',
        titulo: '',
        texto: '',
        btn_confirm_texto: '',
        btn_confirm_icono: '',
        btn_confirm_color: '',
        paciente_id: 0,
        revision_id: 0,
        revision_indice: 0,
      },
      loading: false,
      colorBotonSubir: 'blue',
      fini: null,
      iniFormatted: null,
      finFormatted: null,
      menu: false,
      auxdate: null,
      seen: true,
      es_doctorclic: nameEnv === 'doctorclic',
    };
  },
  watch: {
    fini(_val) {
      this.iniFormatted = this.formatDate(this.fini);
    },
  },
  methods: {
    abrirRevision(paciente_id, file, revision_id) {
      this.$http
        .post(
          getRevisionFileUrl,
          { id: this.authUser.id, revision_id: revision_id, paciente_id: paciente_id, file: file },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.data.estado === '1') {
            window.open(response.data.url);
          }
        });
    },

    eliminarRevision(paciente_id, revision_id, indice) {
      this.dialog_confirm.dialog = true;
      this.dialog_confirm.tipo = 'eliminar';
      this.dialog_confirm.titulo = this.$t('mensajeria.delete_file');
      this.dialog_confirm.texto = this.$t('mensajeria.confirmar_delete_file');
      this.dialog_confirm.btn_confirm_texto = this.$t('common.confirm');
      this.dialog_confirm.btn_confirm_icono = 'delete';
      this.dialog_confirm.btn_confirm_color = 'red';
      this.dialog_confirm.paciente_id = paciente_id;
      this.dialog_confirm.revision_id = revision_id;
      this.dialog_confirm.revision_indice = indice;
    },

    onNuevoChild(respuesta) {
      if (respuesta.estado === '1') {
        this.videoconsultas['pendientes'].push(respuesta.videoconsulta);
      }
      this.alerta.texto =
        respuesta.estado === '1'
          ? this.$t('videoconsulta.enviado_correctamente')
          : this.$t('videoconsulta.enviado_recordatorio');
      this.alerta.color = 'success';
      this.alerta.icono = 'mdi-video';
      this.snackbar = true;
      this.dialog_nuevavideo = false;
    },

    seleccionarRevision() {
      if (!this.disabledButton) {
        const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if (iOS) {
          document.getElementById('filenuevo').setAttribute('accept', '*.*');
        }
        this.$refs.filenew.click();
      }
    },

    onFileChangeNuevo() {
      this.nuevorevision.seleccionado = true;
      this.nuevorevision.file = this.$refs.filenew.files[0];
      if (typeof this.nuevorevision.file !== 'undefined') {
        const tam_max = this.authUser.id === 4612 ? 50000000 : 10000000;
        if (this.nuevorevision.file.size > tam_max) {
          this.alerta.icono = 'mdi-alert-circle';
          this.alerta.texto = this.$t('errors.file_max_upload', { tam: tam_max / 1000000 });
          this.alerta.color = 'error';
          this.snackbar = true;
        } else {
          this.dialog_confirm.dialog = true;
          this.dialog_confirm.tipo = 'subir';
          this.dialog_confirm.titulo = this.$t('revisiones.confirmar_subida_titulo');
          this.dialog_confirm.texto = this.$t('revisiones.confirmar_subida_texto');
          this.dialog_confirm.btn_confirm_texto = this.$t('common.confirm');
          this.dialog_confirm.btn_confirm_icono = 'cloud_upload';
          this.dialog_confirm.btn_confirm_color = 'green';
        }
      }
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    cambiaFecha(item) {
      this.$set(item, 'edit', true);
      this.iniFormatted = this.auxdate;
      item.menud = false;
    },

    actualizaFechaAux(date) {
      this.auxdate = this.formatDate(date);
    },

    editarFechaRevision(item) {
      this.fini = this.parseDate(item.fecha);
      this.iniFormatted = item.fecha;
      if (!item.hidedate) {
        this.$set(item, 'hidedate', true);
      } else {
        this.$set(item, 'hidedate', false);
      }
      this.$set(item, 'menud', false);

      if (item.edit) {
        this.grabarNuevaFecha(item);
      }
    },

    grabarNuevaFecha(item) {
      item.fecha = this.auxdate;
      this.$http
        .post(modificarFechaRevMed, { id: this.authUser.id, item: item, date: this.auxdate }, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            item.edit = false;
            this.showAlerta(this.$t('success.save_common'), 'success', 'mdi-check');
          } else {
            this.showAlerta(this.$t('errors.try_again'), 'info', 'mdi-alert-circle');
          }
        });
    },

    showAlerta(texto, color, icono) {
      this.alerta.texto = texto;
      this.alerta.color = color;
      this.alerta.icono = icono;
      this.snackbar = true;
    },

    uploadCertificate(id, fileid) {
      if (!this.disabledButton) {
        const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if (iOS) {
          document.getElementById(fileid).setAttribute('accept', '*.*');
        }
        this.$refs[fileid][0].click();
      }
    },

    showModalNewRevision() {
      this.dialog.show = true;
      this.dialog.title = this.$t('patient.new_revision');
      this.dialog.type = 'form';
      const items = [
        { id: '0', name: this.$t('patient.pendiente') },
        { id: '1', name: this.$t('patient.aceptado') },
        { id: '2', name: this.$t('documentos.renuncia') },
      ];
      this.dialog.form = [
        {
          model: { name: 'estado', value: '' },
          label: this.$t('common.status'),
          type: 'select',
          items: items,
          required: true,
        },
        { model: { name: 'fecha', value: '' }, label: this.$t('common.date'), type: 'datepicker', required: true },
      ];
      this.dialog.button = {
        color: 'green darken-1',
        action: 'confirmNewRevision',
        text: this.$t('common.confirm'),
        icon: 'mdi-check',
      };
    },

    confirmNewRevision() {
      this.loading = true;
      const tam_max = this.authUser.id === 4612 ? 50000000 : 10000000;
      const new_revision = {
        estado: this.dialog.form[0].model.value,
        fecha: this.dialog.form[1].model.value,
      };
      this.$http
        .post(
          saveNewReco,
          { id: this.authUser.id, paciente_id: this.idpaciente, reconocimiento: new_revision },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200) {
            this.$emit('updateRevisions', response.data.revisiones);
            this.alerta.icono = 'mdi-check';
            this.alerta.texto = this.$t('success.edit_patient');
            this.alerta.color = 'success';
            this.snackbar = true;
          } else {
            this.alerta.icono = 'mdi-alert-circle';
            this.alerta.texto = this.$t('errors.file_max_upload', { tam: tam_max / 1000000 });
            this.alerta.color = 'error';
            this.snackbar = true;
          }
          this.loading = false;
        });
    },

    showSnackbar(alert) {
      this.showAlerta(alert.text, alert.color, alert.icon);
    },

    onFileCertif(id, estado) {
      let idfile = 'filecertif';
      if (estado === 2) {
        idfile = 'fileresig';
      }

      const tam_max = this.authUser.id === 4612 ? 50000000 : 10000000;
      const pdf = this.$refs[idfile + '-' + id][0].files[0];
      const formData = new FormData();
      formData.append('reconocimiento_id', id);
      formData.append('estado', estado);
      formData.append('id_paciente', this.idpaciente);
      if (typeof pdf !== 'undefined') {
        formData.append('file', pdf);
      }

      this.loading = true;

      this.$http.post(saveStatusReco, formData, { headers: getHeader() }).then(response => {
        if (response.status === 200) {
          this.$emit('update:revisiones', response.data.revisiones);
          this.alerta.icono = 'mdi-check';
          this.alerta.texto = this.$t('success.edit_patient');
          this.alerta.color = 'success';
          this.snackbar = true;
        } else {
          this.alerta.icono = 'mdi-alert-circle';
          this.alerta.texto = this.$t('errors.file_max_upload', { tam: tam_max / 1000000 });
          this.alerta.color = 'error';
          this.snackbar = true;
        }
        this.loading = false;
      });
    },

    formatDateTimeToDate(date) {
      if (!date) return null;
      const _date = date.split(' ')[0];
      const [year, month, day] = _date.split('-');
      return `${day}/${month}/${year}`;
    },

    obtenerEstadoUnificado(estado) {
      let nuevoEstado = estado;

      switch (estado) {
        case 1:
          nuevoEstado = 1;
          break;
        case 2:
          nuevoEstado = 2;
          break;
        case 0:
        case 3:
        case 4:
        case 6:
        case 5:
        case 7:
          nuevoEstado = 0;
          break;
      }

      return nuevoEstado;
    },
  },
};
</script>

<style>
.cabecera_nuevo_reconocimiento {
  text-align: right;
}
.item_adjunto:hover {
  cursor: pointer;
  background: rgba(182, 218, 255, 0.25);
}
.item_adjunto {
  width: 100%;
}

.inputFecha {
  max-width: 140px;
}

.align-to-right {
  width: 100%;
  text-align: right;
}
</style>
